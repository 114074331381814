<template>
  <div class="process">
    <Header back="返回" title="项目列表" index="首页" titleOne="项目管理" titleTwo="项目列表" beforeTitle="流程" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="ClickTab">
        <!-- 流程 -->
        <el-tab-pane label="流程" name="first">
          <h1 style="display: inline-block">{{ order_name }}</h1>
          <el-button class="addFolder" @click="exportFile">一键导出</el-button>
          <el-steps :active="active" class="step_hover" simple style="margin-top: 20px">
            <el-step
              v-for="(item, index) in step"
              :key="index"
              :title="item.sort == '结案' ? item.sort : 'setp' + item.sort"
              @click.native="next(index, item.id)"
            ></el-step>
          </el-steps>
          <div class="module_name_box">
            <h3 class="module_name">{{module_name}}</h3>
            <el-button class="addFolder" @click="addFolder">加文件夹</el-button>
          </div>
          <div class="clear"></div>
          <el-tree
            class="outer"
            v-if="!resultText"
            ref="tree"
            :data="data"
            node-key="id"
            default-expand-all
            draggable
            :expand-on-click-node="false"
            accordion
            @node-click="infoClick"
          >
            <div class="tree-node" slot-scope="{ node, data }">
              <div class="tree-node">
                <img
                  v-if="data.extension == 'folder'"
                  class="folder_icon"
                  src="../../assets/images/folder_icon.png"
                  alt
                />
                <img
                  v-if="data.extension == 'doc' || data.extension == 'docx'"
                  class="folder_icon"
                  src="../../assets/images/doc_icon.png"
                  alt
                />
                <img
                  v-if="data.extension == 'pdf'"
                  class="folder_icon"
                  src="../../assets/images/pdf_icon.png"
                  alt
                />
                <img
                  v-if="data.extension == 'xls' || data.extension == 'xlsx'"
                  class="folder_icon"
                  src="../../assets/images/xls_icon.png"
                  alt
                />
                <span v-if="data.file_name && !node.checked" class="node-tit">
                  {{
                  data.file_name
                  }}
                </span>
                <div v-else style="display: inline-block;margin-left: 10px;">
                  <el-input @click.native="inputClick" v-model="data.file_name"></el-input>
                  <el-button @click.stop="nosureLable(node, data)" class="save-btn">取消</el-button>
                  <el-button
                    style="margin-left:0"
                    @click.stop="saveLable(node, data)"
                    class="save-btn"
                  >保存</el-button>
                </div>
                <span class="More" @click="moreClick">
                  <el-dropdown trigger="click" class="inner">
                    <span class="el-dropdown-link">
                      <i style="font-size: 20px;" class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        class="pro_btn"
                        @click.native="() => nodeClick(node,data)"
                      >编辑</el-dropdown-item>
                      <!-- <el-dropdown-item @click.native="() => nodeLook(node,data)">预览</el-dropdown-item> -->
                      <el-dropdown-item
                        class="pro_btn uploads"
                        v-if="!data.file_name.includes('.doc'||'.xlsx'||'.txt'||'.pdf')"
                        @click.native="() => nodeUpload(node,data)"
                      >
                        <el-upload
                          class="upload-demo"
                          v-model="file_Name"
                          ref="imgupload"
                          action="string"
                          :http-request="httpRequest"
                          :show-file-list="false"
                          :before-upload="beforeAvatarUpload"
                          :file-list="fileList"
                        >
                          <el-button type="primary" class="ChooseFile">上传</el-button>
                        </el-upload>
                      </el-dropdown-item>
                      <el-dropdown-item class="pro_btn" @click.native="() => nodeDownload(node)">下载</el-dropdown-item>
                      <el-popconfirm
                        title="确认将此文件夹/文件同步到企业资料吗？"
                        v-if="data.extension != 'folder'"
                        @confirm="syncSure(node,data)"
                      >
                        <el-dropdown-item slot="reference" class="pro_btn">同步到企业</el-dropdown-item>
                      </el-popconfirm>
                      <!-- <template #default="{row,$index}"> -->
                      <el-dropdown-item class="yinyong" v-if="data.extension == 'folder'">
                        <el-popover ref="popoverCode" placement="left" width="400" trigger="click">
                          <div style="text-align: center;">
                            <el-autocomplete
                              class="inline-input"
                              v-model="quoteParams.keywords"
                              :fetch-suggestions="quoteSearchAsync"
                              @select="quoteSelect"
                              placeholder="请输入引用模板名称"
                            ></el-autocomplete>
                            <el-button
                              style="margin-top:24px"
                              @click.native="() => nodeQuote(node)"
                              type="primary"
                            >确定</el-button>
                          </div>
                          <el-button
                            slot="reference"
                            @click.native="() => quoteParams.keywords = ''"
                            class="yinyong_btn"
                            type="text"
                          >引用</el-button>
                        </el-popover>
                      </el-dropdown-item>
                      <!-- </template> -->
                      <el-dropdown-item class="pro_btn" @click.native="() => nodeShare(node)">分享</el-dropdown-item>
                      <el-dropdown-item class="pro_btn" @click.native="() => remove(node, data)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </el-tree>
          <el-dialog title="同步进度" :visible.sync="dialogSync" width="30%" top="20%">
            <h2 class="starts">正在同步企业资料，请稍后…..</h2>
            <el-progress :stroke-width="12" :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
              <!-- <el-button @click="dialogSync = false">取 消</el-button> -->
              <el-button style="margin:10px 0;" type="primary" @click="dialogSync = false">完 成</el-button>
            </span>
          </el-dialog>
          <div class="emptyText" v-if="resultText">
            <h1>结案详情</h1>
            <h2>
              结案状态
              <span v-if="order_status == 2">通过</span>
              <span v-if="order_status == 3">未通过</span>
            </h2>
            <h2>
              结案时间
              <span v-if="finish_time">{{ finish_time | fmtdate }}</span>
            </h2>
            <h2 v-if="order_status == 2">
              通过时间
              <span v-if="pass_time">{{ pass_time | fmtdate }}</span>
            </h2>
            <h2 v-if="order_status == 3">
              原因
              <span v-if="explain">{{ explain }}</span>
            </h2>
          </div>
          <el-dialog :visible.sync="dialogExportform" width="25%">
            <el-form
              :model="exportform"
              :label-position="labelPosition"
              :rules="addRule"
              ref="addRule"
            >
              <el-checkbox-group v-model="checked" @change="Chchecked">
                <el-checkbox
                  v-for="item in exportdata"
                  :key="item.id"
                  :label="item.id"
                  :value="item.id"
                >{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogExportform = false">取 消</el-button>
              <el-button type="primary" @click="savechecked()">确 定</el-button>
            </div>
          </el-dialog>
          <el-dialog title="结案" :visible.sync="dialogFormVisible" width="400px">
            <el-form
              :model="finishform"
              :label-position="labelPosition"
              :rules="finishRule"
              ref="finishRule"
            >
              <el-form-item label="结案时间" :label-width="formLabelWidth">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  v-model="finishform.finish_time"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="结案状态" :label-width="formLabelWidth">
                <el-select v-model="finishform.status" placeholder="请选择状态">
                  <el-option label="通过" :value="2">通过</el-option>
                  <el-option label="未通过" :value="3">未通过</el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="通过时间"
                :label-width="formLabelWidth"
                v-if="finishform.status == 2"
              >
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  v-model="finishform.pass_time"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="未通过说明"
                :label-width="formLabelWidth"
                v-if="finishform.status == 3"
              >
                <el-input
                  type="textarea"
                  v-model="finishform.explain"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saverole()">确 定</el-button>
            </div>
          </el-dialog>
          <el-dialog title="添加文件夹" :visible.sync="dialogaddForm" width="400px">
            <el-form
              :model="addform"
              :label-position="labelPosition"
              :rules="addRule"
              ref="addRule"
            >
              <el-form-item label="文件夹名称" :label-width="formLabelWidth">
                <el-input type="input" v-model="addform.file_name"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" :loading="saveLoading" @click="savefolder()">确 定</el-button>
            </div>
          </el-dialog>
          <el-dialog title="引用模板" :visible.sync="QuoteForm" width="400px">
            <el-form :model="quoteform" :label-position="labelPosition">
              <el-form-item>
                <el-select
                  v-model="quoteform.typeMoudel"
                  placeholder="请选择引用模板类型"
                  @change="getValue"
                >
                  <el-option
                    v-for="item in typeMoudel"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="quoteform.name"
                  :fetch-suggestions="querySearchAsync"
                  @select="handleSelect"
                  placeholder="请输入模板名称关键字"
                  v-if="this.quoteform.Moudeltype == 'search'"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item>
                <el-select v-model="quoteform.quotelist" multiple>
                  <el-option
                    v-for="item in quotelist"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="savefolder()">确 定</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <!-- 企业信息 -->
        <el-tab-pane label="企业信息" name="second">
          <el-tabs v-model="activeName1" @tab-click="handleClick">
            <el-tab-pane label="项目信息" name="project">
              <el-form :model="projectform" label-width="80px">
                <ul>
                  <li>
                    <span>项目名称</span>
                    {{ projectform.order_name }}
                  </li>
                  <li>
                    <span>企业名称</span>
                    {{ projectform.company_name }}
                  </li>
                  <li>
                    <span>合同名称</span>
                    {{ projectform.contract_name }}
                  </li>
                  <li>
                    <span>项目类型</span>
                    {{ projectform.product_type_name }}
                  </li>
                  <li>
                    <span>年度</span>
                    {{ projectform.order_year }}
                  </li>
                  <li>
                    <span>批次</span>
                    {{ projectform.order_batch }}
                  </li>
                  <li>
                    <span>截止时间</span>
                    {{ projectform.end_time }}
                  </li>
                  <li>
                    <span>协助人员</span>
                    {{ projectform.helper_str }}
                  </li>
                </ul>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="info" class="third">
              <div class="edit_info" @click="editInfo()">
                <i class="el-icon-edit-outline"></i>
                <span>完善信息</span>
              </div>
              <el-form :model="baseform" label-width="80px">
                <ul>
                  <li>
                    <span>企业名称</span>
                    {{ baseform.company_name }}
                  </li>
                  <li>
                    <span>法人代表</span>
                    {{ baseform.legal_person }}
                  </li>
                  <li>
                    <span>企业简称</span>
                    {{ baseform.short_name }}
                  </li>
                  <li>
                    <span>业务经理</span>
                    {{ baseform.business_manager }}
                  </li>
                  <li>
                    <span>组织机构代码</span>
                    {{ baseform.org_code }}
                  </li>
                  <li>
                    <span>业务经理手机</span>
                    {{ baseform.tel }}
                  </li>
                  <li>
                    <span>信用代码</span>
                    {{ baseform.code }}
                  </li>
                  <li>
                    <span>客服管家</span>
                    {{ baseform.project_specialist }}
                  </li>
                  <li>
                    <span>注册资本（万元）</span>
                    {{ baseform.register_capital }}
                  </li>
                  <li>
                    <span>联系人</span>
                    {{ baseform.contact }}
                  </li>
                  <li>
                    <span>注册时间</span>
                    {{ baseform.register_time }}
                  </li>
                  <li>
                    <span>固定电话</span>
                    {{ baseform.mobile }}
                  </li>
                  <li>
                    <span>是否高企</span>
                    <i v-if="baseform.is_high == 0">否</i>
                    <i v-if="baseform.is_high == 1">是</i>
                  </li>
                  <li>
                    <span>邮箱</span>
                    {{ baseform.email }}
                  </li>
                  <li>
                    <span>所属区域</span>
                    {{ baseform.area }}
                  </li>
                  <li>
                    <span>企业网址</span>
                    {{ baseform.domain }}
                  </li>
                  <li>
                    <span>企业类型</span>
                    <i v-if="baseform.com_type == 1">有限责任公司</i>
                    <i v-if="baseform.com_type == 2">股份有限公司</i>
                    <i v-if="baseform.com_type == 3">国企</i>
                    <i v-if="baseform.com_type == 4">外商投资企业</i>
                    <i v-if="baseform.com_type == 5">独资企业</i>
                    <i v-if="baseform.com_type == 6">个体工商户</i>
                    <i v-if="baseform.com_type == 7">联营企业</i>
                    <i v-if="baseform.com_type == 8">集体所有制</i>
                    <i v-if="baseform.com_type == 9">有限合</i>
                    <i v-if="baseform.com_type == 10">普通合伙</i>
                  </li>
                  <li>
                    <span>专利数量</span>
                    {{ baseform.patent_count }}
                  </li>
                  <li>
                    <span>经营状态</span>
                    <i v-if="baseform.status == 1">在业</i>
                    <i v-if="baseform.status == 2">存续</i>
                    <i v-if="baseform.status == 3">正常</i>
                    <i v-if="baseform.status == 4">注销</i>
                    <i v-if="baseform.status == 5">筹建</i>
                    <i v-if="baseform.status == 6">清算</i>
                    <i v-if="baseform.status == 7">迁入</i>
                    <i v-if="baseform.status == 8">迁出</i>
                    <i v-if="baseform.status == 9">停业</i>
                    <i v-if="baseform.status == 10">撤销</i>
                    <i v-if="baseform.status == 11">吊销</i>
                  </li>
                  <li>
                    <span>软著数量</span>
                    {{ baseform.soft_count }}
                  </li>
                  <li>
                    <span>所属行业</span>
                    {{ baseform.industry_one }}
                  </li>
                  <li>
                    <span>商标数量</span>
                    {{ baseform.brand_count }}
                  </li>
                  <li>
                    <span>资产规模</span>
                    <i v-if="baseform.asset_scale == 1">2000万元以上 ~ 5000万元（含）</i>
                    <i v-if="baseform.asset_scale == 2">5000万元以上 ~ 1亿元（含）</i>
                    <i v-if="baseform.asset_scale == 3">1亿元以上 ~ 2亿元（含）</i>
                    <i v-if="baseform.asset_scale == 4">2亿元以上 ~ 4亿元（含）</i>
                    <i v-if="baseform.asset_scale == 5">4亿元以上</i>
                  </li>
                  <li>
                    <span>登记机关</span>
                    {{ baseform.register_org }}
                  </li>
                  <li>
                    <span>公司销售额（万元）</span>
                    {{ baseform.sales_scale }}
                  </li>
                  <li>
                    <span>注册地址</span>
                    {{ baseform.register_address }}
                  </li>
                  <li>
                    <span>研发投入（万元）</span>
                    {{ baseform.yftr }}
                  </li>
                  <li>
                    <span>公司地址</span>
                    {{ baseform.address }}
                  </li>
                  <li>
                    <span>经营范围</span>
                    {{ baseform.jyfw }}
                  </li>
                </ul>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="人员信息" name="human">
              <div class="edit_info" @click="editInfoHuman()">
                <i class="el-icon-edit-outline"></i>
                <span>完善信息</span>
              </div>
              <el-form :model="humanform" label-width="80px">
                <h1>总体情况</h1>
                <dl>
                  <dt>人员类型</dt>
                  <dt>企业职工（人）</dt>
                  <dt>科技人员（人）</dt>
                  <dd>
                    <span>总人数</span>
                    <span>{{ humanform.q_zs }}</span>
                    <span>{{ humanform.k_zs }}</span>
                  </dd>
                  <dd>
                    <span>其中：在职人员</span>
                    <span>{{ humanform.q_zz }}</span>
                    <span>{{ humanform.k_zz }}</span>
                  </dd>
                  <dd>
                    <span>兼职人员</span>
                    <span>{{ humanform.q_jz }}</span>
                    <span>{{ humanform.k_jz }}</span>
                  </dd>
                  <dd>
                    <span>临时聘用人员</span>
                    <span>{{ humanform.q_ls }}</span>
                    <span>{{ humanform.k_ls }}</span>
                  </dd>
                  <dd>
                    <span>外籍人员</span>
                    <span>{{ humanform.q_wj }}</span>
                    <span>{{ humanform.k_wj }}</span>
                  </dd>
                  <dd>
                    <span>留学归国人员</span>
                    <span>{{ humanform.q_lx }}</span>
                    <span>{{ humanform.k_lx }}</span>
                  </dd>
                  <dd>
                    <span>千人计划人员</span>
                    <span>{{ humanform.q_gr }}</span>
                    <span>{{ humanform.k_gr }}</span>
                  </dd>
                </dl>
                <h1>全体人员结构</h1>
                <dl>
                  <dt>结构类型</dt>
                  <dt>人数</dt>
                  <dd>
                    <span>博士</span>
                    <span>{{ humanform.x_bs }}</span>
                  </dd>
                  <dd>
                    <span>硕士</span>
                    <span>{{ humanform.x_ss }}</span>
                  </dd>
                  <dd>
                    <span>本科</span>
                    <span>{{ humanform.x_bk }}</span>
                  </dd>
                  <dd>
                    <span>大专及以下</span>
                    <span>{{ humanform.x_dz }}</span>
                  </dd>
                  <dd>
                    <span>高级职称</span>
                    <span>{{ humanform.z_gj }}</span>
                  </dd>
                  <dd>
                    <span>中级职称</span>
                    <span>{{ humanform.z_zj }}</span>
                  </dd>
                  <dd>
                    <span>初级职称</span>
                    <span>{{ humanform.z_cj }}</span>
                  </dd>
                  <dd>
                    <span>高级技工</span>
                    <span>{{ humanform.z_jg }}</span>
                  </dd>
                  <dd>
                    <span>30岁及以下</span>
                    <span>{{ humanform.l_one }}</span>
                  </dd>
                  <dd>
                    <span>31岁-40岁</span>
                    <span>{{ humanform.l_two }}</span>
                  </dd>
                  <dd>
                    <span>41岁-50岁</span>
                    <span>{{ humanform.l_three }}</span>
                  </dd>
                  <dd>
                    <span>51岁及以上</span>
                    <span>{{ humanform.l_four }}</span>
                  </dd>
                </dl>
                <h1>企业职工人数情况表</h1>
                <dl>
                  <dt>职工人数情况类型</dt>
                  <dt>人数</dt>
                  <dd>
                    <span>全年月平均在职人员①</span>
                    <span>{{ humanform.qly }}</span>
                  </dd>
                  <dd>
                    <span>其中，参保人员</span>
                    <span>{{ humanform.cbrs }}</span>
                  </dd>
                  <dd>
                    <span>工作时间超过183天的兼职人员②</span>
                    <span>{{ humanform.jzrs }}</span>
                  </dd>
                  <dd>
                    <span>工作时间超过183天的临时聘用人员③</span>
                    <span>{{ humanform.lsrs }}</span>
                  </dd>
                  <dd>
                    <span>企业职工总数（①+②+③）</span>
                    <span>{{ humanform.qyzrs }}</span>
                  </dd>
                  <!-- <dd><span>留学归国人员</span><span>{{humanform.}}</span></dd> -->
                  <dd>
                    <span>全年月平均在职人员数与参保人数差异的情况说明</span>
                    <span>{{ humanform.qksm }}</span>
                  </dd>
                </dl>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <!-- 科创关联 -->
        <el-tab-pane label="科创关联" name="third">
          <el-tabs v-model="activeName2" @tab-click="clickPS">
            <!-- PS产品 -->
            <el-tab-pane label="PS产品" name="first">
              <div class="psBox">
                <el-row>
                  <el-button class="psAdd" @click="addrelation1()">新增关联</el-button>
                </el-row>
                <h1>PS产品关联数据</h1>
                <!-- 数据表格 -->
                <el-table
                  max-height="450px"
                  style="width: 100%"
                  :data="relation.data"
                  row-key="id"
                  class="sortablePS"
                >
                  <el-table-column width="100" prop="new_sort" label="sort">
                    <i style="cursor: pointer; font-size: 20px" class="el-icon-sort"></i>
                  </el-table-column>
                  <el-table-column prop="code" label="PS编号" width="130">
                    <template slot-scope="scope">{{ scope.row.code ? scope.row.code : "-" }}</template>
                  </el-table-column>
                  <el-table-column prop="ps_name" label="产品名称" width="400">
                    <template slot-scope="scope">
                      {{
                      scope.row.ps_name ? scope.row.ps_name : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column width="250px" prop="sales" label="上年销售额收入(万元)">
                    <template slot-scope="scope">{{ scope.row.sales ? scope.row.sales : "-" }}</template>
                  </el-table-column>
                  <el-table-column
                    :show-overflow-tooltip="true"
                    prop="supporting_materials"
                    label="证明材料"
                    width="550"
                  >
                    <template slot-scope="scope">
                      {{
                      scope.row.supporting_materials
                      ? scope.row.supporting_materials
                      : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" width="120px">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="lookPS(scope.row.id)">查看</el-button>
                      <el-button
                        style="color: #ff0f0f"
                        type="text"
                        size="small"
                        @click="delPS(scope.row.id)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <!-- RD项目 -->
            <el-tab-pane label="RD项目" name="second">
              <div class="psBox">
                <el-row>
                  <el-button class="psAdd" @click="addrelation2()">新增关联</el-button>
                </el-row>
                <h1>RD产品关联数据</h1>
                <!-- 数据表格 -->
                <el-table
                  height="450"
                  :data="relationRD.data"
                  style="width: 100%"
                  class="sortableRD"
                  row-key="id"
                >
                  <el-table-column width="100" prop="adress" label="sort">
                    <i style="cursor: pointer; font-size: 20px" class="el-icon-sort"></i>
                  </el-table-column>
                  <el-table-column prop="code" label="RD编号" width="120">
                    <template slot-scope="scope">{{ scope.row.code ? scope.row.code : "-" }}</template>
                  </el-table-column>
                  <el-table-column prop="rd_name" label="项目名称" width="220">
                    <template slot-scope="scope">
                      {{
                      scope.row.rd_name ? scope.row.rd_name : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column :formatter="formatterRD" prop="rd_type" label="研发类型" width="100"></el-table-column>
                  <el-table-column
                    prop="start_time"
                    :formatter="formatterTime"
                    label="项目起止时间"
                    width="230"
                  ></el-table-column>
                  <el-table-column prop="charge_person" label="负责人" width="120">
                    <template slot-scope="scope">
                      {{
                      scope.row.charge_person ? scope.row.charge_person : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="rd_personnel" label="研发人数" width="160">
                    <template slot-scope="scope">
                      {{
                      scope.row.rd_personnel ? scope.row.rd_personnel : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="total_budget" label="总预算(万元)" width="160">
                    <template slot-scope="scope">
                      {{
                      scope.row.total_budget ? scope.row.total_budget : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="first_budget" label="第一年" width="70">
                    <template slot-scope="scope">
                      {{
                      scope.row.first_budget ? scope.row.first_budget : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="second_budget" label="第二年" width="70">
                    <template slot-scope="scope">
                      {{
                      scope.row.second_budget ? scope.row.second_budget : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="third_budget" label="第三年" width="70">
                    <template slot-scope="scope">
                      {{
                      scope.row.third_budget ? scope.row.third_budget : "-"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" width="120">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="lookRD(scope.row.id)">查看</el-button>
                      <el-button
                        style="color: #ff0f0f"
                        type="text"
                        size="small"
                        @click="delRD(scope.row.id)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <!-- IP产权 -->
            <el-tab-pane label="IP产权" name="third">
              <div class="psBox">
                <el-row>
                  <el-button class="psAdd" @click="addrelation3()">新增关联</el-button>
                </el-row>
                <h1>IP产品关联数据(已授权)</h1>
                <!-- 数据表格 -->
                <el-table
                  height="300px"
                  :data="relationIP.data.authorize_data"
                  style="width: 100%"
                  class="sortablePatent1"
                  row-key="id"
                >
                  <el-table-column width="100" prop="adress" label="sort">
                    <i style="cursor: pointer; font-size: 20px" class="el-icon-sort"></i>
                  </el-table-column>
                  <el-table-column prop="code" label="IP编号" width="120"></el-table-column>
                  <el-table-column prop="patent_name" label="知识产权名称" width="170"></el-table-column>
                  <el-table-column
                    :formatter="formatterPatent"
                    prop="get_type"
                    label="类别"
                    width="114"
                  ></el-table-column>
                  <el-table-column prop="public_date" label="授权日期" width="143"></el-table-column>
                  <el-table-column prop="public_code" label="授权号" width="138"></el-table-column>
                  <el-table-column prop="get_type" label="获得方式" width="143"></el-table-column>
                  <el-table-column prop="app_date" label="申请时间" width="142"></el-table-column>
                  <el-table-column prop="last_pay_time" label="最后缴费" width="143"></el-table-column>
                  <el-table-column prop="rd_code" label="对应RD" width="134"></el-table-column>
                  <el-table-column prop="ps_code" label="对应PS" width="87"></el-table-column>
                  <el-table-column prop="address" label="操作" width="120">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="lookIP(scope.row.id)">查看</el-button>
                      <el-button
                        style="color: #ff0f0f"
                        type="text"
                        size="small"
                        @click="delIP(scope.row.id)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <h1>IP产品关联数据(受理中)</h1>
                <!-- 数据表格 -->
                <el-table
                  height="300"
                  :data="relationIP.data.under_authorize_data"
                  style="width: 100%"
                  class="sortablePatent2"
                  row-key="id"
                >
                  <el-table-column width="100" prop="adress" label="sort">
                    <i style="cursor: pointer; font-size: 20px" class="el-icon-sort"></i>
                  </el-table-column>
                  <el-table-column prop="code" label="IP编号" width="120"></el-table-column>
                  <el-table-column prop="patent_name" label="知识产权名称" width="170"></el-table-column>
                  <el-table-column
                    :formatter="formatterIP"
                    prop="patent_type"
                    label="类别"
                    width="114"
                  ></el-table-column>
                  <el-table-column prop="public_date" label="授权日期" width="143"></el-table-column>
                  <el-table-column prop="public_code" label="授权号" width="138"></el-table-column>
                  <el-table-column prop="get_type" label="获得方式" width="143"></el-table-column>
                  <el-table-column prop="app_date" label="申请时间" width="142"></el-table-column>
                  <el-table-column prop="last_pay_time" label="最后缴费" width="143"></el-table-column>
                  <el-table-column prop="rd_code" label="对应RD" width="134"></el-table-column>
                  <el-table-column prop="ps_code" label="对应PS" width="87"></el-table-column>
                  <el-table-column prop="address" label="操作" width="120">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="lookIP(scope.row.id)">查看</el-button>
                      <el-button
                        style="color: #ff0f0f"
                        type="text"
                        size="small"
                        @click="delIP(scope.row.id)"
                      >删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      <!-- PS弹出层 -->
      <el-dialog top="35vh" title="新增PS关联" :visible.sync="dialogVisiblePS" width="20%">
        <el-form ref="formRule" :model="psParams" label-position="top" label-width="80px">
          <el-form-item label="产品名称">
            <el-autocomplete
              class="inline-input"
              v-model="psParams.ps_name"
              :fetch-suggestions="querySearchPS"
              placeholder="请输入产品名称筛选"
              :trigger-on-focus="false"
              @select="handleSelectPS"
              :value="psParams.ps_id"
              @change="change_ps"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <el-cascader
              style="width: 100%"
              placeholder="请选择需要关联的RD/IP  可多选"
              :options="optionsPS"
              @change="changePS"
              v-model="selectPS"
              :props="{ multiple: true, checkStrictly: true, emitPath: false }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblePS = false">取 消</el-button>
          <el-button type="primary" :loading="psLoading" @click="onSubmitPS()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- RD弹出层 -->
      <el-dialog top="35vh" title="新增RD关联" :visible.sync="dialogVisibleRD" width="20%">
        <el-form ref="formRule" :model="rdParams" label-position="top" label-width="80px">
          <el-form-item label="项目名称">
            <el-autocomplete
              class="inline-input"
              v-model="rdParams.rd_name"
              :fetch-suggestions="querySearchRD"
              placeholder="请输入项目名称筛选"
              :trigger-on-focus="false"
              @select="handleSelectRD"
              :value="rdParams.rd_id"
              @change="change_rd"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <el-cascader
              style="width: 100%"
              placeholder="请选择需要关联的PS/IP  可多选"
              :options="optionsRD"
              :props="{ multiple: true, checkStrictly: true, emitPath: false }"
              v-model="selectRD"
              @change="changeRD"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleRD = false">取 消</el-button>
          <el-button type="primary" :loading="rdLoading" @click="onSubmitRD()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- IP弹出层 -->
      <el-dialog top="35vh" title="新增IP关联" :visible.sync="dialogVisibleIP" width="20%">
        <el-form ref="formRule" :model="ipParams" label-position="top" label-width="80px">
          <el-form-item label="IP类别">
            <el-select v-model="form.region" placeholder="请选择IP类别">
              <el-option
                v-for="item in downList"
                :key="item.type"
                :label="item.value"
                :value="item.type"
              ></el-option>
              <!-- <el-option label="软著" :value="2"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="知识产权名称">
            <el-autocomplete
              class="inline-input"
              v-model="ipParams.patent_name"
              :fetch-suggestions="querySearchIP"
              placeholder="请输入知识产权名称筛选"
              :trigger-on-focus="false"
              :value="ipParams.patent_id"
              @select="handleSelectIP"
              @change="change_ip"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item>
            <el-cascader
              style="width: 100%"
              placeholder="请选择需要关联的PS/RD  可多选"
              :options="optionsIP"
              :props="{ multiple: true, checkStrictly: true, emitPath: false }"
              v-model="selectIP"
              @change="changeIP"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleIP = false">取 消</el-button>
          <el-button type="primary" :loading="ipLoading" @click="onSubmitIP()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- PS查看抽屉 -->
      <el-drawer
        size="50%"
        title="我是标题"
        :visible.sync="openPS"
        :with-header="false"
        :v-model="pslooklist"
      >
        <div class="drawerTitle">
          <span>{{ pslooklist.ps_name }}</span>
        </div>
        <div class="drawerBody">
          <div class="bodyLeft">
            <ul>
              <li>
                <p>PS编号</p>
                <span>{{ pslooklist.ps_code }}</span>
              </li>
              <li>
                <p>产品（服务）名称</p>
                <span>{{ pslooklist.ps_name }}</span>
              </li>
              <li>
                <p>技术领域</p>
                <span>{{ pslooklist.technology_str }}</span>
              </li>
              <li>
                <p>技术来源</p>
                <span>{{ pslooklist.technology_source }}</span>
              </li>
              <li>
                <p>是否主要产品</p>
                <span>{{ pslooklist.is_main }}</span>
              </li>
              <li>
                <p>已选知识产权编号</p>
                <span>{{ pslooklist.ip_code }}</span>
              </li>
              <li>
                <p>关键技术及主要技术指标</p>
                <span>{{ pslooklist.main_technology }}</span>
              </li>
              <li>
                <p>与同类产品（服务）的竞争优势</p>
                <span>{{ pslooklist.advantage }}</span>
              </li>
              <li>
                <p>知识产权获得情况及其对产品（服务）在技术上发挥的支持作用</p>
                <span>{{ pslooklist.effect }}</span>
              </li>
            </ul>
          </div>
        </div>
      </el-drawer>
      <!-- RD查看抽屉 -->
      <el-drawer
        size="50%"
        title="我是标题"
        :visible.sync="openRD"
        :with-header="false"
        :v-model="rdlooklist"
      >
        <div class="drawerTitle">
          <span>{{ rdlooklist.rd_name }}</span>
        </div>
        <div class="drawerBody">
          <div class="bodyLeft">
            <ul>
              <li>
                <p>RD编号</p>
                <span>{{ rdlooklist.rd_code }}</span>
              </li>
              <li>
                <p>研发活动名称</p>
                <span>{{ rdlooklist.rd_name }}</span>
              </li>
              <li>
                <p>开始日期</p>
                <span>{{ rdlooklist.start_time }}</span>
              </li>
              <li>
                <p>结束日期</p>
                <span>{{ rdlooklist.end_time }}</span>
              </li>
              <li>
                <p>技术领域</p>
                <span>{{ rdlooklist.technology_str }}</span>
              </li>
              <li>
                <p>已选知识产权编号</p>
                <span>{{ rdlooklist.ip_code }}</span>
              </li>
              <li>
                <p>研发经费总预算（万元）</p>
                <span>{{ rdlooklist.total_budget }}</span>
              </li>
              <li>
                <p>研发经费近三年总支出（万元）</p>
                <span>{{ rdlooklist.three_year_budget }}</span>
              </li>
              <li>
                <p>其中第一年（2019）</p>
                <span>{{ rdlooklist.first_budget }}</span>
              </li>
              <li>
                <p>其中第二年（2020）</p>
                <span>{{ rdlooklist.second_budget }}</span>
              </li>
              <li>
                <p>其中第三年（2021）</p>
                <span>{{ rdlooklist.third_budget }}</span>
              </li>
              <li>
                <p>目的及组织实施方式（限400字）</p>
                <span>{{ rdlooklist.advantage }}</span>
              </li>
              <li>
                <p>核心技术及创新点（限400字）</p>
                <span>{{ rdlooklist.technology }}</span>
              </li>
              <li>
                <p>取得的阶段性成果（限400字）</p>
                <span>{{ rdlooklist.effect }}</span>
              </li>
            </ul>
          </div>
        </div>
      </el-drawer>
      <!-- IP查看抽屉 -->
      <el-drawer
        size="50%"
        title="我是标题"
        :visible.sync="openIP"
        :with-header="false"
        :v-model="iplooklist"
      >
        <div class="drawerTitle">
          <span>{{ iplooklist.patent_name }}</span>
        </div>
        <div class="drawerBody">
          <div class="bodyLeft">
            <ul>
              <li>
                <p>IP编号</p>
                <span>{{ iplooklist.ip_code }}</span>
              </li>
              <li>
                <p>类别</p>
                <span v-if="iplooklist.patent_type == 1">发明专利</span>
                <span v-if="iplooklist.patent_type == 2">实用新型</span>
                <span v-if="iplooklist.patent_type == 3">外观设计</span>
                <span v-if="iplooklist.patent_type == 4">植物新品种</span>
                <span v-if="iplooklist.patent_type == 5">国家新药</span>
                <span v-if="iplooklist.patent_type == 6">集成电路布图设计专有权</span>
                <span v-if="iplooklist.patent_type == 7">国家级农作物品种</span>
                <span v-if="iplooklist.patent_type == 8">国家一级中药保护品种</span>
                <span v-if="iplooklist.patent_type == 9">国防专利</span>
                <span v-if="iplooklist.patent_type == 10">软件著作</span>
              </li>
              <li>
                <p>专利号</p>
                <span>{{ iplooklist.public_code }}</span>
              </li>
              <li>
                <p>知识产权名称</p>
                <span>{{ iplooklist.patent_name }}</span>
              </li>
              <li>
                <p>获得方式</p>
                <span>{{ iplooklist.get_type }}</span>
              </li>
              <li>
                <p>授权日期</p>
                <span>{{ iplooklist.public_date }}</span>
              </li>
            </ul>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import site from "../../components/global.vue";
export default {
  inject: ["reload"],

  data () {
    return {
      quoteVis: false,
      filedataId: '',
      quoteParams: {
        keywords: '',
        id: '',
        new_file_name: '',
      },
      timeout: null,
      saveLoading: false,//加文件夹提交按钮动画
      psLoading: false,//ps新增提交按钮动画
      rdLoading: false,//rd新增提交按钮动画
      ipLoading: false,//ip新增提交按钮动画
      time: null,
      progress: 0,
      dialogSync: false,
      file_up_btn: '',//判断是文件还是文件夹（是否有上传按钮）
      file_up: [],
      module_name: '',//流程名称
      selectPS: ["rd", "patent"],
      selectRD: ["ps", "patent"],
      selectIP: ["ps", "rd"],
      typeLists: [
        {
          value: "发明专利",
          type: 1,
        },
        {
          value: "实用新型",
          type: 2,
        },
        {
          value: "外观设计",
          type: 3,
        },
        {
          value: "植物新品种",
          type: 4,
        },
        {
          value: "国家新药",
          type: 5,
        },
        {
          value: "集成电路布图设计专有权",
          type: 6,
        },
        {
          value: "国家级农作物品种",
          type: 7,
        },
        {
          value: "国家一级中药保护品种",
          type: 8,
        },
        {
          value: "国防专利",
          type: 9,
        },
        {
          value: "软件著作",
          type: 10,
        },
      ],
      downList: [
        {
          value: "专利",
          type: 1,
        },
        {
          value: "软著",
          type: 2,
        },
      ],
      optionsPS: [
        {
          value: "rd",
          label: "同时关联RD",
        },
        {
          value: "patent",
          label: "同时关联IP",
        },
      ],
      optionsRD: [
        {
          value: "ps",
          label: "同时关联PS",
        },
        {
          value: "ip",
          label: "同时关联IP",
        },
      ],
      optionsIP: [
        {
          value: "ps",
          label: "同时关联PS",
        },
        {
          value: "rd",
          label: "同时关联RD",
        },
      ],
      ippatentParams: {
        patent_name: "",
        type: "patent",
        com_id: "",
      },
      ipsoftParams: {
        page: 1,
        limit: 10,
        soft_name: "",
        type: "soft",
        com_id: "",
      },
      ipParams: {
        page: 1,
        limit: 10,
        order_id: this.$route.query.id,
        patent_name: "",
        patent_id: "",
        related: "", //    ps/patent/空
      },
      rdParams: {
        page: 1,
        limit: 10,
        order_id: this.$route.query.id,
        rd_name: "",
        rd_id: "",
        related: "", //    ps/patent/空
      },
      psParams: {
        page: 1,
        limit: 10,
        order_id: this.$route.query.id,
        ps_name: "",
        ps_id: "",
        related: "",
      },
      psLists: {
        page: 1,
        limit: 10,
        type: "ps",
        order_id: this.$route.query.id,
      },
      rdLists: {
        page: 1,
        limit: 10,
        type: "rd",
        order_id: this.$route.query.id,
      },
      ipLists: {
        page: 1,
        limit: 10,
        type: "patent",
        order_id: this.$route.query.id,
      },
      form: {
        region: "",
        c: "",
        patent_name: "",
        company_name: "",
        patent_type: "",
        status: "",
        app_code: "",
        public_code: "",
        app_date: "",
        public_date: "",
        classify_code: "",
        address: "",
        organ: "",
        agent: "",
        engineer: "",
        process: "",
        business_type: "",
        certificate_date: "",
        get_type: "",
        desc: "",
        rd_name: "",
        rd_id: "",
        com_id: "",
        ps_name: "",
        ps_id: "",
        patent_id: "",
      },
      openPS: false,
      openRD: false,
      openIP: false,
      dialogVisibleIP: false,
      dialogVisibleRD: false,
      dialogVisiblePS: false,
      userSite: site.userSite,
      webSite: site.webSite,
      activeName: "first",
      activeName1: "project",
      activeName2: "first",
      active: 0,
      step: [],
      order_name: "",
      labelPosition: "top",
      dialogFormVisible: false,
      dialogaddForm: false,
      QuoteForm: false,
      formLabelWidth: "80px",
      baseform: {},
      finishform: {
        end_time: "",
        status: "",
        pass_time: "",
        explain: "",
        order_module_id: "",
      },
      dialogExportform: false,
      exportform: {},
      addform: {
        file_name: "",
        order_file_id: "",
      },
      quoteform: {
        typeMoudel: "",
        Moudeltype: "",
        quotelist: [],
        model_id: "",
        name: "",
      },
      quotelist: [],
      addRule: {},
      finishRule: {},
      humanform: {
        q_zs: "",
        k_zs: "",
        q_zz: "",
        k_zz: "",
        q_jz: "",
        k_jz: "",
        q_ls: "",
        k_ls: "",
        q_wj: "",
        k_wj: "",
        q_lx: "",
        k_lx: "",
        q_gr: "",
        k_gr: "",
        x_bs: "",
        x_ss: "",
        x_bk: "",
        x_dz: "",
        z_gj: "",
        z_zj: "",
        z_cj: "",
        z_jg: "",
        l_one: "",
        l_two: "",
        l_three: "",
        l_four: "",
        qly: "",
        cbrs: "",
        jzrs: "",
        lsrs: "",
        qyzrs: "",
        qksm: "",
        com_id: "",
      },
      projectform: {
        order_name: "",
        company_name: "",
        contract_name: "",
        product_type_name: "",
        order_year: "",
        order_batch: "",
        finish_time: "",
        helper: "",
      },
      data: [],
      resultText: false, // 用于已结案
      file_Name: "",
      order_status: "",
      finish_time: "",
      pass_time: "",
      explain: "",
      file_url: "",
      fileList: [],
      exportdata: [],
      checked: [],
      typeMoudel: [],
      lookParamsPS: {},
      pslooklist: {
        ps_name: "",
        com_id: "",
        technology_one: "",
        technology_two: "",
        technology_three: "",
        ps_code: "",
        technology_source: "",
        sales: "",
        supporting_materials: "",
        is_main: "",
        main_technology: "",
        advantage: "",
        effect: "",
        key_technology: "",
        kdj: "",
        technology_str: "",
        ip_code: "",
      },
      rdlooklist: {
        rd_name: "",
        start_time: "",
        end_time: "",
        technology_one: "",
        technology_two: "",
        technology_three: "",
        third_year: "",
        total_budget: "",
        first_budget: "",
        second_budget: "",
        third_budget: "",
        first_year: "",
        second_year: "",
        three_year_budget: "",
        technology: "",
        advantage: "",
        effect: "",
        technology_str: "",
        ip_code: "",
      },
      iplooklist: {
        patent_type: "",
        public_code: "",
        get_type: "",
        public_date: "",
        patent_name: "",
        ip_code: "",
      },
      // psid: "",
      sortpslist: {
        type: "ps",
        sort_data: [{ id: "", new_sort: "" }],
      },
      sortrdlist: {
        type: "rd",
        sort_data: [{ id: "", new_sort: "" }],
      },
      sortiplist: {
        type: "patent",
        sort_data: [{ id: "", new_sort: "" }],
      },
    };
  },
  created () {
    this.getProcess();
    this.$store.dispatch("getrelationIP", this.ipLists);
  },
  mounted () {
    this.getMounted()
  },
  computed: {
    ...mapState(["relation", "relationRD", "relationIP"]),
    meber_id () {
      return this.$route.query.id;
    },
  },
  methods: {
    getMounted () {

      this.axios
        .get("/api/order/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.order_name = res.data.order_name;
          this.com_id = res.data.com_id;
          console.log(this.com_id, "com_id");
        }),
        this.axios
          .get("/api/order/get_order_process", {
            params: {
              id: this.meber_id,
            },
          })
          .then((res) => {
            this.step = res.data;
            this.step[this.step.length - 1].sort = '结案'
            // console.log(this.step[this.step.length - 1], '流程step');
            // console.log('当前流程：', this.step[this.active], this.active)
            this.module_name = this.step[this.active].name
            this.$nextTick(() => {
              // this.active = this.step.findIndex((item) => {
              //   return item.status != 2;
              // });
              if (this.active == -1) {
                this.active = res.data.length - 1;
              }
              if (this.active < this.step.length - 1) {
                this.getFileData(this.step[this.active].id);
              }
              if (
                this.active == this.step.length - 1 &&
                this.step[this.active].status == 2
              ) {
                this.resultText = true;
                this.getfinishModel(this.step[this.active].id);
              }
            })
          });
    },

    quoteSearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/quote/select_quote", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.name,
                  id: element.id,
                });
                // console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    quoteSelect (item) {
      console.log(item, 'item');
      this.quoteParams.id = item.id;
      this.quoteParams.new_file_name = item.value;
    },



    //更多点击事件
    moreClick (e) {
      e.stopPropagation() // 阻止事件冒泡
    },
    // 同步到企业
    syncSure (node, data) {
      console.log(node, data, 999)
      this.dialogSync = true
      this.time = setInterval(() => {
        this.progress += parseInt(Math.random() * 6)
        if (this.progress > 99) {
          clearInterval(this.time)
        }
      }, 500)
      this.axios.post('/api/order/sync_company_material', {
        order_file_id: node.data.id,
        com_id: this.meber_id,
      }).then((res) => {
        this.progress = 100
        clearInterval(this.time)
        this.$message.success(res.message)
      }).catch(() => {
        clearInterval(this.time)
      })
    },
    // 完善信息-基本
    editInfo () {
      this.$router.push({ path: '/project/Process/info_clone', query: { id: this.com_id, name: 'info' } })
    },
    // 完善信息-人员  
    editInfoHuman () {
      this.$router.push({ path: '/partner/detail', query: { id: this.com_id, name: 'roster' } })
    },
    //ps行拖拽
    rowDropPS () {
      const tbody = document.querySelector(".sortablePS tbody");
      console.log(tbody, "ps列表");
      var _this = this;
      Sortable.create(tbody, {
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.relation.data.splice(oldIndex, 1)[0];
          _this.relation.data.splice(newIndex, 0, currRow);
          console.log(oldIndex, "oldindex");
          // this.axios.post("/api/order/updata_sort", this.sortpslist);
          // _this.psid = _this.relation.data[newIndex].id;
          _this.axios
            .post("/api/order/update_sort", {
              ..._this.sortpslist,
              sort_data: _this.relation.data.map((data, index) => {
                return { id: data.id, new_sort: index + 1 };
              }),
            })
            .then(() => {
              _this.$store.dispatch("getrelation", {
                type: "ps",
                order_id: _this.$route.query.id,
              });
              _this.$message({
                type: "success",
                message: "排序成功",
              });
            });
        },
      });
    },
    //rd行拖拽
    rowDropRD () {
      const tbody = document.querySelector(".sortableRD tbody");
      var _this = this;
      if (_this.relationRD.data) {
        Sortable.create(tbody, {
          onEnd ({ newIndex, oldIndex }) {
            const currRow = _this.relationRD.data.splice(oldIndex, 1)[0];
            _this.relationRD.data.splice(newIndex, 0, currRow);
            _this.axios
              .post("/api/order/update_sort", {
                ..._this.sortrdlist,
                sort_data: _this.relationRD.data.map((data, index) => {
                  return { id: data.id, new_sort: index + 1 };
                }),
              })
              .then(() => {
                _this.$store.dispatch("getrelationRD", {
                  type: "rd",
                  order_id: _this.$route.query.id,
                });
                _this.$message({
                  type: "success",
                  message: "排序成功",
                });
              });
          },
        });
      }
    },
    //ip已授权行拖拽
    rowDropPatent1 () {
      const tbody = document.querySelector(".sortablePatent1 tbody");
      var _this = this;
      if (_this.relationIP.data.authorize_data) {
        Sortable.create(tbody, {
          onEnd ({ newIndex, oldIndex }) {
            const currRow = _this.relationIP.data.authorize_data.splice(
              oldIndex,
              1
            )[0];
            _this.relationIP.data.authorize_data.splice(newIndex, 0, currRow);
            _this.axios
              .post("/api/order/update_sort", {
                ..._this.sortiplist,
                sort_data: _this.relationIP.data.authorize_data.map(
                  (data, index) => {
                    return { id: data.id, new_sort: index + 1 };
                  }
                ),
              })
              .then(() => {
                _this.$store.dispatch("getrelationIP", {
                  type: "patent",
                  order_id: _this.$route.query.id,
                });
                _this.$message({
                  type: "success",
                  message: "排序成功",
                });
              });
          },
        });
      }
    },
    //ip处理中行拖拽
    rowDropPatent2 () {
      const tbody = document.querySelector(".sortablePatent2 tbody");
      var _this = this;
      if (_this.relationIP.data.under_authorize_data) {
        Sortable.create(tbody, {
          onEnd ({ newIndex, oldIndex }) {
            const currRow = _this.relationIP.data.under_authorize_data.splice(
              oldIndex,
              1
            )[0];
            _this.relationIP.data.under_authorize_data.splice(
              newIndex,
              0,
              currRow
            );
            _this.axios
              .post("/api/order/update_sort", {
                ..._this.sortiplist,
                sort_data: _this.relationIP.data.under_authorize_data.map(
                  (data, index) => {
                    return { id: data.id, new_sort: index + 1 };
                  }
                ),
              })
              .then(() => {
                _this.$store.dispatch("getrelationIP", {
                  type: "patent",
                  order_id: _this.$route.query.id,
                });
                _this.$message({
                  type: "success",
                  message: "排序成功",
                });
              });
          },
        });
      }
    },
    clickPS (tab, event) {
      // console.log(tab, event);
      if (tab.paneName == "first") {
        this.$store.dispatch("getrelation", this.psLists);
        this.rowDropPS();
      }
      if (tab.paneName == "second") {
        this.$store.dispatch("getrelationRD", this.rdLists);
        this.rowDropRD();
      }
      if (tab.paneName == "third") {
        this.$store.dispatch("getrelationIP", this.ipLists);
        this.rowDropPatent1();
        this.rowDropPatent2();
      }
    },
    change_ip () {
      console.log(this.ipParams);
    },
    change_rd () {
      console.log(this.rdParams);
    },
    change_ps () {
      console.log(this.psParams);
    },
    changeIP () {
      console.log(this.selectIP);
    },
    changeRD () {
      console.log(this.selectRD);
    },
    changePS () {
      console.log(this.selectPS);
    },
    // IP产权关联数据（已授权）的 类别 渲染
    formatterPatent (row, column) {
      return this.typeLists.find((item) => item.type === row.patent_type).value;
    },
    // IP产权关联数据（处理中）的 类别 渲染
    formatterIP (row, column) {
      return this.typeLists.find((item) => item.type === row.patent_type).value;
    },
    // RD项目列表起止时间拼接
    formatterTime (row, column) {
      return `${row.start_time} 至 ${row.end_time}`;
    },
    //RD项目列表 研发类型 渲染
    formatterRD (row, column) {
      return this.typeLists.find((item) => item.type === row.rd_type).value;
    },
    lookPS (id) {
      this.openPS = true;
      this.axios
        .get("/api/order/view_order_ps", {
          params: { id: id },
        })
        .then((res) => {
          this.pslooklist = res.data;
        });
    },
    lookRD (id) {
      this.openRD = true;
      this.axios
        .get("/api/order/view_order_rd", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.rdlooklist = res.data;
        });
    },
    lookIP (id) {
      this.openIP = true;
      this.axios
        .get("/api/order/view_order_patent", {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.iplooklist = res.data;
        });
    },
    delPS (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/order/delete_order_ps", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getrelation", this.psLists);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delRD (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/order/delete_order_rd", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getrelationRD", this.rdLists);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delIP (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/order/delete_order_patent", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getrelationIP", this.ipLists);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // ps新增数据关联提交按钮
    onSubmitPS () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild && this.psParams.ps_name) {
          this.psLoading = true
          this.axios
            .post("/api/order/add_order_ps", {
              ...this.psParams,
              related: this.selectPS.join(),
            })
            .then((res) => {

              // this.$router.push("/memo/memoMore");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.psLoading = false
              this.isDisable = true;
              this.dialogVisiblePS = false;
              this.$store.dispatch("getrelation", this.psLists);
              this.psParams.ps_name = "";
              this.selectPS = [];
            })
            .catch((res) => {
              console.log("err:", res);
              this.psLoading = false
            });
        } else {
          console.log(this.$store.state.relation.data, "relation--data");
          this.$message({
            type: "warning",
            message: "请选择要关联的数据",
          });
        }
        // if (this.$store.state.relation.data.ps_name === this.psParams.ps_name) {
        //   console.log("数据已经存在");
        // }
      });
    },
    // rd新增数据关联提交按钮
    onSubmitRD () {
      this.$refs.formRule.validate((vaild) => {
        if ((vaild, this.rdParams.rd_name)) {
          this.rdLoading = true
          this.axios
            .post("/api/order/add_order_rd", {
              ...this.rdParams,
              related: this.selectRD.join(),
            })
            .then((res) => {
              // this.$router.push("/memo/memoMore");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.rdLoading = false
              this.isDisable = true;
              this.dialogVisibleRD = false;
              this.$store.dispatch("getrelationRD", this.rdLists);
              this.rdParams.rd_name = "";
              this.selectRD = [];
            })
            .catch((res) => {
              this.rdLoading = false
              console.log("err:", res);
            });
        } else {
          this.$message({
            type: "warning",
            message: "请选择要关联的数据",
          });
        }
      });
    },
    // ip新增数据关联提交按钮
    onSubmitIP () {
      this.$refs.formRule.validate((vaild) => {
        if ((vaild, this.ipParams.patent_name)) {
          this.ipLoading = true
          this.axios
            .post("/api/order/add_order_patent", {
              ...this.ipParams,
              related: this.selectIP.join(),
              type: this.form.region,
            })
            .then((res) => {
              this.ipLoading = false
              // this.$router.push("/memo/memoMore");
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.isDisable = true;
              this.dialogVisibleIP = false;
              this.$store.dispatch("getrelationIP", this.ipLists);
              this.form.region = [];
              this.ipParams.patent_name = "";
              this.selectIP = [];
            })
            .catch((res) => {
              console.log("err:", res);
              this.ipLoading = false
            });
        } else {
          this.$message({
            type: "warning",
            message: "请选择要关联的数据",
          });
        }
      });
    },
    // ps新增数据筛选
    querySearchPS (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/ps/select_ps_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.data.length) {
              cb(
                res.data.map((item) => {
                  return {
                    value: item.ps_name,
                    id: item.id,
                  };
                })
              );
            }
          });
      }
    },
    // rd新增数据筛选
    querySearchRD (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/rd/select_rd_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.length) {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                results.push({
                  value: element.rd_name,
                  id: element.id,
                });
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    // IP产权筛选
    querySearchIP (queryString, cb) {
      console.log(this.form.region);
      // 当this.form.region || type为1时 调取patent列表
      if (this.form.region == 1) {
        this.axios
          .get("/api/order/view", {
            params: {
              id: this.meber_id,
            },
          })
          .then((res) => {
            this.order_name = res.data.order_name;
            this.com_id = res.data.com_id;
            console.log(this.com_id, "com_id");
          }),
          clearTimeout(this.timeout);
        let results = [];
        if (queryString == "") {
          cb(results);
        } else {
          this.axios
            .get("/api/patent/select_scientific", {
              params: {
                keywords: queryString,
                ...this.ippatentParams,
                com_id: this.com_id,
              },
            })
            .then((res) => {
              cb(
                res.data.map((item) => {
                  return {
                    id: item.id,
                    value: item.name,
                  };
                })
              );
            });
        }
      }
      if (this.form.region == 2) {
        //当this.form.region || type为2时   调取soft列表
        this.axios
          .get("/api/order/view", {
            params: {
              id: this.meber_id,
            },
          })
          .then((res) => {
            this.order_name = res.data.order_name;
            this.com_id = res.data.com_id;
            console.log(this.com_id, "com_id");
          }),
          clearTimeout(this.timeout);
        let results = [];
        if (queryString == "") {
          cb(results);
        } else {
          this.axios
            .get("/api/patent/select_scientific", {
              params: {
                keywords: queryString,
                ...this.ipsoftParams,
                com_id: this.com_id,
              },
            })
            .then((res) => {
              cb(
                res.data.map((item) => {
                  return {
                    id: item.id,
                    value: item.name,
                  };
                })
              );
            });
        }
      }
    },
    handleSelectPS (item) {
      this.psParams.ps_name = item.value;
      this.psParams.ps_id = item.id;
    },
    handleSelectRD (item) {
      this.rdParams.rd_name = item.value;
      this.rdParams.rd_id = item.id;
    },
    handleSelectIP (item) {
      console.log(item);
      this.ipParams.patent_name = item.value;
      this.ipParams.patent_id = item.id;
    },

    // 点击显示PS产品中新增关联模态框
    addrelation1 () {
      this.dialogVisiblePS = true;
      this.psParams.ps_name = "";
      this.selectPS = [];
    },
    // 点击显示PS产品中新增关联模态框

    addrelation2 () {
      this.dialogVisibleRD = true;
      this.rdParams.rd_name = "";
      this.selectRD = [];
    },
    // 点击显示PS产品中新增关联模态框

    addrelation3 () {
      this.dialogVisibleIP = true;
      this.ipParams.patent_name = "";
      this.form.region = [];
      this.selectIP = [];
    },
    exportFile () {
      this.dialogExportform = true;
      this.getexportdata();
    },
    getexportdata () {
      this.axios
        .get("/api/order/get_export_module", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.exportdata = res.data;
        });
    },
    Chchecked () {
      console.log(this.checked);
    },
    savechecked () {
      this.checked = this.checked.toString();
      console.log(this.checked);
      this.axios
        .get("/api/order/export_module", {
          params: {
            order_module_id: this.checked,
          },
        })
        .then((res) => {
          window.open(this.userSite + res.data.url, "_blank");
          this.dialogExportform = false;
        });
    },
    nodeShare (node) {
      this.axios
        .post("/api/order/share_file", {
          order_file_id: node.data.id,
        })
        .then((res) => {
          this.getFileData(this.filedataId);
          window.open(res.data.url, "_blank");
          this.$forceUpdate();
        });
    },
    nodeDownload (node) {
      this.axios
        .get("/api/order/download", {
          params: {
            order_file_id: node.data.id,
          },
        })
        .then((res) => {
          console.log(node.data.id);
          this.getFileData(this.filedataId);
          window.open(this.userSite + res.data.url, "_blank");
          this.$forceUpdate();
        });
    },
    nodeQuote (node) {
      // this.QuoteForm = true;
      this.axios.post('/api/quote/order_quote', {
        order_id: this.meber_id,
        order_file_id: node.data.id,
        quote_file_id: this.quoteParams.id,
      }).then((res) => {
        console.log(res, '引用·····', this.$refs.popoverCode)
        this.$message.success(res.message)
        this.getMounted()
        // this.$refs.popoverCode.doClose()
        // this.quoteVis = false
        document.body.click()
      }).catch((err) => {
        this.$message.error(err.message)
      })
    },
    getType () {
      this.axios.get("/api/order/get_quote_model").then((res) => {
        this.typeMoudel = res.data;
      });
    },
    getValue (id) {
      this.model_name = id;
      this.quoteform.Moudeltype = this.typeMoudel[id].type;
      this.quoteform.quotelist = [];
      this.quotelist = this.typeMoudel[id].list;
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/patent/select_scientific", {
            params: {
              keywords: queryString,
              com_id: this.com_id,
              type: this.model_name,
            },
          })
          .then((res) => {
            if (res.message == "查询成功！") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                results.push({
                  value: element.name,
                  id: element.model_id,
                });
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },

    //点击出现搜索后点击的每一项
    handleSelect (item) {
      // console.log(item);
      this.quoteform.model_id = item.id;
      this.quoteform.name = item.value;
    },
    nodeUpload (node, data) {
      this.order_file_id = node.data.id;
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("order_file_id", this.order_file_id);
      this.upload("/api/order/upload_file", form).then((res) => {
        this.getFileData(this.filedataId);
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    getProcess () {
      this.axios
        .get("/api/order/get_order_process", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.step = res.data;
          for (var i = 0; i < this.step.length - 1; i++) {
            if (this.step[i].status != 2 && i < this.step.length - 2) {
              this.active = i
              break
            } else {
              this.active = i
            }
            // this.step.find((item) => { return item.status == 2 }).then(() => {
            //   this.active = i + 1
            // })
          }
        });
    },
    judgeStatus (id) {
      if (this.active == this.step.length) return false;
      this.axios
        .post("/api/order/next_module", {
          order_module_id: id,
        })
        .then((res) => {
          this.getProcess();
        });
    },
    next (num, id) {
      console.log(num, id, this.step, '流程点击', this.step[num].status)
      this.filedataId = id;
      //this.getFileData(id)
      // this.judgeStatus(id)
      if (num > this.active) {
        if (this.step[num - 1].status == 1 && num - 1 == this.active) {
          this.judgeStatus(this.step[num - 1].id);
          this.getFileData(id)
          this.module_name = this.step[num].name
        }
      }
      this.active = num
      // if (num < this.step.length - 1 && this.step[num].status != 0) {
      //   this.getFileData(id);
      // }
      if (this.step[num].status != 0) {
        this.module_name = this.step[num].name
        this.getFileData(id);

      }
      if (num == this.step.length - 1 && this.step[num].status < 2) {
        // 流程结束
        this.dialogFormVisible = true;
      }
      if (num == this.step.length - 1 && this.step[num].status == 2) {
        this.resultText = true;
        this.getfinishModel(id);
      }
      // if (num == this.step.length - 1) {
      //   this.dialogFormVisible = true;
      // }
      this.finishform.order_module_id = id;
    },
    getFileData (id) {
      // this.data = []
      console.log(this.data, 888)
      this.axios
        .get("/api/order/get_order_file", {
          params: {
            order_module_id: id,
          },
        })
        .then((res) => {
          this.file_Name = res.data.file_name;
          const temData = res.data.children
          this.addform.order_file_id = res.data.id;
          this.data = temData;
          this.$forceUpdate();
          console.log(temData, 999)

          setTimeout(() => {
            this.resultText = false;
          }, 500);
        })
        .catch((c) => {
          this.data = [];
        });
    },
    getfinishModel (id) {
      this.axios
        .get("/api/order/get_finish_module", {
          params: {
            order_module_id: id,
          },
        })
        .then((res) => {
          this.order_status = res.data.order_status;
          this.finish_time = res.data.finish_time;
          this.pass_time = res.data.pass_time;
          this.explain = res.data.explain;
        });
    },

    saverole () {
      // 确认结案
      this.$refs.finishRule.validate((vaild) => {
        if (vaild) {
          this.axios
            .post("/api/order/finish_module", this.finishform)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              console.log(res);
              this.dialogFormVisible = false;
              this.getProcess()
              // this.getFileData()
            })
            .catch((res) => {
              console.log("err:", res);
            });
        } else {
          this.$message.error("");
        }
      });
    },
    ClickTab (tab, event) {
      if (this.activeName == "second" && this.meber_id) {
        this.axios
          .get("/api/order/view", {
            params: {
              id: this.meber_id,
              type: this.activeName,
            },
          })
          .then((res) => {
            console.log(res.data.help_name);
            this.projectform = res.data;
            this.com_id = res.data.com_id;
          });
      }
      if (this.activeName2 == "first") {
        this.$store.dispatch("getrelation", this.psLists);
        console.log("ps产权列表");
        this.rowDropPS();
      }
    },
    handleClick (tab, event) {
      if (this.activeName1 == "human" && this.meber_id) {
        console.log(this.activeName1);
        this.axios
          .get("/api/company/view_relation", {
            params: {
              id: this.com_id,
              type: this.activeName1,
            },
          })
          .then((res) => {
            console.log(res);
            this.humanform = res.data;
          });
      } else if (this.activeName1 == "info" && this.meber_id) {
        this.axios
          .get("/api/company/view", {
            params: {
              id: this.com_id,
            },
          })
          .then((res) => {
            this.baseform = res.data;
          });
      }
    },
    addFolder () {
      this.dialogaddForm = true;
    },

    savefolder () {
      this.$refs.addRule.validate((vaild) => {
        if (vaild) {
          this.saveLoading = true
          this.axios
            .post("/api/order/add_folder", this.addform)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.saveLoading = false
              this.dialogaddForm = false;
              this.getFileData(this.filedataId);
              this.$forceUpdate();
              this.getMounted()
            })
            .catch((res) => {
              this.saveLoading = false
              console.log("err:", res);
            });
        } else {
          this.$message.error("");
        }
      });
    },
    // nodeLook (node, data) {
    //   let docUrl = this.userSite + data.url
    //   let url = encodeURIComponent(docUrl)
    //   let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url
    //   // 在新窗口打开编码后 的链接
    //   window.open(officeUrl, '_target')
    //   console.log(node, data, 'node', officeUrl, docUrl)
    //   // var openDocObj = new ActiveXObject("SharePoint.OpenDocuments.2");
    //   // openDocObj.EditDocument("http://www.abc.com/documents/sample.doc");


    // },
    //获取当前节点修改checked值
    nodeClick (node, data) {
      // node.checked = true;
      if (data.extension != 'folder' && data.extension != '') {
        let file_id = data.id
        let type = data.extension
        const routeData = this.$router.resolve({
          path: '/wpsfile',      //跳转目标窗口的地址
          query: {
            file_id,
            type   //括号内是要传递给新窗口的参数
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        node.checked = true;
      }
    },
    //删除节点
    remove (node, data) {
      this.$confirm("确定删除此数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/order/delete_file", {
            params: {
              order_file_id: data.id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getFileData(this.filedataId);
            this.getMounted()
            this.$forceUpdate();
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    infoClick (node, data) {
      if (node.extension == 'folder' && node.extension) {
        data.expanded = !data.expanded
      } else {
        let file_id = node.id
        let type = node.extension
        const routeData = this.$router.resolve({
          path: '/wpsfile',      //跳转目标窗口的地址
          query: {
            file_id,
            type   //括号内是要传递给新窗口的参数
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
    inputClick (event) {
      event.stopPropagation();
    },
    nosureLable (node, event) {
      node.checked = false
    },
    //保存label
    saveLable (node) {
      this.axios
        .post("/api/order/store_filename", {
          order_file_id: node.data.id,
          file_name: node.data.file_name,
        })
        .then((res) => {
          this.$message.success('保存成功')
          // this.getFileData(this.filedataId);
          node.checked = false;
        });
      // node.checked = false;
    },
  },
};
</script>

<style scoped="scoped">
i {
  font-style: normal;
}

.More {
  float: right;
}

.tree-node {
  width: 100%;
}

.tree-node .el-input {
  width: 300px;
}

::v-deep .el-tree-node__content {
  height: 40px !important;
}

ul li {
  width: 100%;
  color: #909399;
}
#pane-project ul li {
  margin-bottom: 20px;
  margin-top: 10px;
}
ul li span {
  color: #404040;
  display: inline-block;
  /* width: 150px;
  margin-right: 20px; */
}

dl dt {
  display: inline-block;
  width: 33.33%;
  color: #cbcfe3;
  border-bottom: 1px solid #edeef4;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

dl dd {
  border-bottom: 1px solid #edeef4;
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin-left: 0 !important;
}

dl dd span {
  width: 33.33%;
  color: #404040;
  display: inline-block;
}

.third ul li {
  width: 50%;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 10px;
}

.el-select {
  width: 100%;
}

.addFolder {
  float: right;
  margin: 20px 0 10px;
  background: #edeef4;
  border: none;
  color: #909399;
}

.ChooseFile {
  background: none !important;
  border: none;
  color: #909399;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.ChooseFile span {
  display: inline-block;
  width: 100%;
  text-align: center;
}

div/deep/.uploads .el-dropdown-menu__item:hover .ChooseFile {
  color: #3f60ff !important;
}
.uploads .el-button--primary:focus,
.el-button--primary:hover {
  color: #3f60ff !important;
}

/* .upload-demo {
  display: inline-block;
  width: 100%;
} */

.treeitem {
  height: 40px;
  line-height: 40px;
}

.More .el-button {
  border: none;
  color: #0000ff;
  /* padding: 0; */
  margin-right: 10px;
  /* display: inline-block; */
  /* height: 40px; */
  /* line-height: 40px; */
  font-weight: bold;
}
.el-button--text {
  padding: 8px 6px !important;
}
.dropdown {
  /* width: 200px; */
  text-align: center;
}

.el-dropdown-menu {
  /* width: 100px !important; */
  text-align: center;
}

.el-dropdown-menu li {
  padding: 0 !important;
}

.emptyText h2 {
  font-weight: 400 !important;
}

.emptyText span {
  color: #a6abc7 !important;
  margin-left: 20px;
}

::v-deep .el-upload {
  width: 100%;
  text-align: center;
}

::v-deep .el-tabs__content {
  top: 22px;
}

.el-checkbox {
  width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .psBox {
  height: 1000px;
} */
.el-tab-pane {
  height: 100%;
}
.psAdd {
  margin-bottom: 30px;
  background: #0f38ff;
  color: #fff;
}
.drawerTitle {
  width: 92%;
  margin: 20px auto;
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 20px;
}
.drawerTitle span {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.el-drawer {
  width: 50%;
}
.drawerBody {
  width: 92%;
  margin: 20px auto;
  display: flex;
}
.drawerBody .bodyLeft {
  width: 100%;
}
.drawerBody .bodyLeft li {
  position: relative;
  display: flex;
  align-items: center;
  color: #404040;
}

.drawerBody .bodyLeft li span {
  width: 65%;
  position: absolute;
  right: 0;
  display: inline-block;
  color: #909399;
}
.drawerBody .bodyLeft li p {
  display: inline-block;
  width: 25%;
}
.el-steps--simple {
  width: 100%;
  margin-bottom: 20px;
}
div/deep/.el-step {
  flex-basis: initial !important;
}
div/deep/.el-step__title.is-wait {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__head.is-wait {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__title.is-finish {
  color: #000;
}
/* div/deep/.el-step__head.is-success {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__title.is-success {
  color: #000;
} */
div/deep/.el-step__head.is-finish {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__title.is-process {
  color: #3f60ff;
  font-size: 18px;
}
div/deep/.el-step__head.is-process {
  color: #3f60ff;
  border-color: #3f60ff;
}
div/deep/.el-step.is-simple {
  min-width: 200px;
}
div/deep/.el-step__title {
  cursor: pointer;
}
.module_name_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.module_name {
  font-size: 20px;
}
.el-steps--simple {
  overflow: auto;
}
div/deep/.el-tree-node__content {
  padding-right: 20px;
}
.edit_info {
  width: 7%;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #0f38ff;
  cursor: pointer;
}
.edit_info span {
  margin-left: 5px;
}
div/deep/.el-table {
  margin-bottom: 50px;
}
.pro_btn {
  width: 80px !important;
}
/* .tree-node {
  display: flex;
} */
.folder_icon {
  width: 15px;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
div/deep/.el-icon-more {
  width: 50px;
  line-height: 40px;
}
div/deep/.el-dropdown {
  text-align: center;
}
div/deep/.node-tit {
  line-height: 40px;
}
.yinyong .yinyong_btn {
  color: #909399;
}
.yinyong:hover .yinyong_btn {
  color: #0f38ff;
}
</style>
